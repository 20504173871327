.exceptionHandlingTab {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 2%;
  padding-bottom: 2px;
}

.realTimeAdminTab {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 2%;
  padding-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.top-right-modal .modal-dialog {
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.top-right-modal .modal-content {
  border-radius: 0px !important;
  height: 100vh !important;
}

.top-right-modal-md .modal-dialog {
  margin-top: 0px !important;
  margin-right: 20.5% !important;
  margin-bottom: 0px !important;
}

.top-right-modal-md .modal-content {
  border-radius: 0px !important;
  height: 100vh !important;
  width: 160%;
  /* position: relative;
    right: 5%; */
}

.top-right-modal-lg .modal-dialog {
  margin-top: 0px !important;
  margin-right: 14% !important;
  margin-bottom: 0px !important;
}

.top-right-modal-lg .modal-content {
  border-radius: 0px !important;
  height: 100vh !important;
  width: 120%;
  /* position: relative;
    right: 5%; */
}


.bottomArea {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 2% 4%;
  position: absolute;
  bottom: 5px;
  right: 5px;
  /* z-index: ; */
}

.modalHead {
  display: flex;
  justify-content: space-between;
  background: #455470 0% 0% no-repeat padding-box;
  opacity: 1;
}

.modalHeadText {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 22px;
  line-height: 18px;
  font-family: 'Open Sans';
  font-weight: normal;
}

.modalCancelBtn {
  font-size: 14px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #25292E;
  opacity: 1;
  background: #FEFEFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid #474748;
  padding: 5px 20px;
}

.modalCancelBtn2 {
  font-size: 15px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FE7344;
  opacity: 1;
  background: transparent;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid #FE7346;
  padding: 5px 20px;
}

.modalHeadText2 {
  font-size: 16px;
  line-height: 18px;
  font-family: 'Open Sans';
  font-weight: normal;
  color: #FFFFFF;
}

.scanWrap {
  background: #F8F8F8 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 6%;
}

.scanWrap input {
  outline: none;
  border: none;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 1;
  width: 85%;
}

.scanInputWrap {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
}

.scanBg {
  background: #FD7246 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: 38px;
  height: 38px;
  color: #FEFEFE;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectWrap {
  display: flex;
  justify-content: space-around;
}

.selectWrap label {
  font-size: 12px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 0.8;
  text-align: start;
  width: 100%;
}

.selectWrap select {
  outline: none;
  width: 190px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
  padding: 4px 2px;
  font-size: 13px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  color: black;
}

.bullUploadReasonTextArea {
  width: 60vh;
  height: 20vh;
  resize: none;
  outline: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Open Sans";
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 1;
}

.otherReasonTextArea {
  width: 60vh;
  height: 12vh;
  resize: none;
  outline: none;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Open Sans";
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 1;
}

.radio-container input[type="radio"] {
  margin-right: 10px;
}

.radio-container label {
  margin-right: 10px;
}

.radio-container {
  display: flex;
  /* Display the radio buttons in a row */
  align-items: center;
  /* Vertically center-align the content within the container */
  margin-bottom: 10px;
  /* Add some space below the radio buttons */
}

.additional-fields {
  display: flex;
  /* Display the additional fields in a row */
  justify-content: space-between;
  /* Space them out evenly */
}

.additional-fields label,
.additional-fields input {
  margin: 0;
  /* Remove margin for labels and input elements */
}

.selectWrap-new {
  display: flex;
  justify-content: start;
  margin-left: 12px;
}

.selectWrap-new label {
  font-size: 12px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 0.8;
  text-align: start;
  width: 100%;
}

.selectWrap-new select {
  outline: none;
  width: 190px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
  padding: 4px 2px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  color: black;
}

.selectWrap-new select:disabled {
  background: #ededed 0% 0% no-repeat padding-box;
}

.selectWrap-email {
  display: flex;
  justify-content: start;
  margin-left: 30px;
}

.selectWrap-email label {
  font-size: 12px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 0.8;
  text-align: start;
  width: 100%;
}

.selectWrap-email select {
  outline: none;
  width: 25vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
  padding: 4px 2px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  color: black;
}

.custom-time-input {
  width: 190px;
}

.chooseDayWrap {
  border: 2px solid grey;
  padding: 5px;
  width: 250px;
  position: absolute;
  left: 35%;
  bottom: 5%;
  box-shadow: 0px 7px 64px #00000012;
  border-radius: 5px;
  background: #FFFFFF;

}

.chooseDayDate {
  height: 25px;
  border: none;
  border-bottom: 1px solid grey;
  font-size: smaller;
}

.selectedDay {
  background: #0096c7;
  border: 1px solid #0096c7;
  color: #FFFFFF;
  font-size: 13px;
  /* font-weight: 500; */
  border-radius: 50px;
  height: 30px;
  width: 40px;
  /* display: flex;
  align-items: center; */
  padding: 1% 1%;
}

.normalDay {
  background: #e2d9d9;
  border: 1px solid #e2d9d9;
  font-size: 13px;
  /* font-weight: 500; */
  border-radius: 50px;
  height: 30px;
  width: 40px;
  /* display: flex;
  align-items: center; */
  padding: 1% 1%;

}

.chooseDateSaveBtn {
  font-size: 12px;
  border: 1px solid #386641;
  background: #386641;
  color: #FFFFFF;
  border-radius: 2px;
}

.chooseDateDiscardBtn {
  font-size: 12px;
  border: 1px solid #bc4749;
  background: #bc4749;
  color: #FFFFFF;
  border-radius: 2px;
}

.selectWrap-location {
  display: flex;
  justify-content: start;
  margin-left: 12px;
}

.selectWrapFilter {
  display: flex;
  justify-content: space-around;
}

.selectWrapFilter label {
  font-size: 12px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  letter-spacing: 0px;
  color: #06152B;
  opacity: 0.8;
  text-align: start;
  width: 100%;
}

.selectWrapFilter select {
  outline: none;
  width: 180px;
  height: 35px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid rgba(69, 84, 112, 0.4);
  border-radius: 4px;
  padding: 4px 2px;
  font-size: 13px;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: normal;
  color: black;
}

.key-value-pairs {
  background-color: peachpuff;
  padding: 10px;
}

.key {
  flex: 1;
  font-weight: bold;
}

.value {
  flex: 1;
  margin-left: 10px;
}

.column {
  flex: 1;
  margin-right: 20px;
}

.key,
.value {
  margin-bottom: 10px;
}

.custom-close-button {
  font-size: 14px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #25292E;
  opacity: 1;
  background: #FEFEFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 64px #00000012;
  border: 1px solid #474748;
  padding: 5px 20px;
}

.customModal-alert-expiry {
  width: 600px;
  padding: 20px;
}

.highlightDifference {
  color: black !important;
  font-weight: 700;
}

.modal-view-audit {
  width: 900px;
  padding: 20px;
}

.audit-value {
  color: #555;
  font-size: small;
  margin-top: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  font-family: 'open sans';
  letter-spacing: 0px;
  color: #36405D;
  text-transform: capitalize;
  opacity: 1;
  /* text-wrap: nowrap; */
  word-break: break-all;
  word-wrap: break-word;
}

.audit-key {
  font-weight: bold;
  font-size: small;
  margin-bottom: auto;
  text-align: center;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  font-family: 'Avenir Next', sans-serif;
  letter-spacing: 0px;
  color: #36405D;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
}

.Audit-trial-table-container {
  max-height: 300px;
  overflow-y: auto;
}

/* Styling the scrollbar itself (width and background) */
.Audit-trial-table-container::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

/* Styling the track (part the thumb travels in) */
.Audit-trial-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the track */
}

/* Styling the thumb (the draggable part of the scrollbar) */
.Audit-trial-table-container::-webkit-scrollbar-thumb {
  background-color: #f59f82;
  /* your color choice for the thumb */
  border-radius: 10px;
  /* rounded corners on the thumb */
  border: 2px solid #f1f1f1;
  /* creates padding around the thumb */
}

/* Optionally: style the thumb on hover */
.Audit-trial-table-container::-webkit-scrollbar-thumb:hover {
  background: #E06337;
  /* darker shade of orange when hovering */
}

.Audit-trial-table-sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  /* Ensure the background of the header is visible */
}

.audit-view-table {
  font-size: 14px;
}


.calender-view-key {
  font-weight: bold;
  font-size: small;
  margin-bottom: auto;
  text-align: center;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  font-family: 'Avenir Next', sans-serif;
  letter-spacing: 0px;
  color: #fcfcfc;
  text-transform: capitalize;
  opacity: 1;
  text-align: left;
}

.calender-view-value {
  color: #555;
  font-size: small;
  margin-top: auto;
  font-weight: 300;
  font-size: 13px;
  line-height: 30px;
  font-family: 'open sans';
  letter-spacing: 0px;
  color: #fcfcfc;
  text-transform: capitalize;
  opacity: 1;
  /* text-wrap: nowrap; */
  word-break: break-all;
  word-wrap: break-word;
}

.rbc-toolbar-label{
  font-weight: 800;
  font-size: 20px;
}