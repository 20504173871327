

.custom-table {
    border-radius: 50% !important;
    width: 100% !important;
    height: auto !important;

}
.test12 {
    height: 100%;
    width: 100%;
    overflow: scroll;
    float: left;
    /* background-color: #FF7344; */
}


.tableHeadRow{
    background: #455570 0% 0% no-repeat padding-box;
    /* border: 1px solid #455570; */
    opacity: 1;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 13px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: 600;
}

.tableDataRow{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ECEBED;
    opacity: 1;
    font-size: 13px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: normal;
    color: #06152B;
}
/* .emptyTableText {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Open Sans';
    font-weight: normal;
    letter-spacing: 0px;
    color: #06152B;
    opacity: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2%;

} */

.tableHeadData2 {
    height: 45px !important;
    /* min-width: 120px;  */
    /* width: auto; */
    /* white-space: nowrap; */
    padding-right: 25px;
    padding-left: 5px;
    /* padding: 8px 8px; */
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    /* position: relative; */
    position: sticky;
    top: 0;
    background: #455570 0% 0% no-repeat padding-box;
    /* border: 1px solid #455570; */
    color: #FFFFFF;
    z-index: 3;
}

.tableHeadData {
    height: 45px !important;
    /* min-width: 120px;  */
    /* width: auto; */
    /* white-space: nowrap; */
    padding-right: 25px;
    padding-left: 5px;
    /* padding: 8px 8px; */
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    /* position: relative; */
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.tableHeadData input {
  border-radius: 15px;
  /* border-color: chartreuse;
  outline-color: chartreuse; */
  padding: 5px 10px;
}

  
  .fixed-column1 {
    right:4.2%;
    /* z-index: 4; */
  }

  .fixed-column2 {
    right: 0px;
    /* z-index: 4; */
  }

  .fixed-column3 {
    right: 7.8%;
    /* z-index: 4; */
  }


.tableDataDelete:hover {
    fill: red;
}
.tablePagination {
    display: flex;
    justify-content: end;
    margin: 1% 4%;
    position: relative;
    bottom: 0px;
    right: 0px;
    clear: both;

}
.paginationBtn {
    outline: none;
    border: none;
    letter-spacing: 0.42px;
    /* color: #090909; */
    text-transform: capitalize;
    opacity: 1;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Open Sans';
    font-weight: 600;
    background: #FFFFFF;
}
.paginationBtn2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #FF7344;
    color: #FF7344;
    border-radius: 4px;
    opacity: 1;
}
.paginationText1 {
    letter-spacing: 0.48px;
    color: #69727F;
    opacity: 1;
    font-size: 12px;
    line-height: 26px;
    font-family: 'Open Sans';
    font-weight: 600;
  }
  .paginationText2 {
    letter-spacing: 0.48px;
    color: #3D3D43;
    opacity: 1;
    font-size: 14px;
    line-height: 26px;
    font-family: 'Open Sans';
    font-weight: 600;
  }

.paginationSelect {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #455570 !important;
    border-radius: 4px;
    opacity: 1;
    outline: none !important;
    box-shadow: none !important;
    letter-spacing: 0px;
    color: #455570;
    padding: 2px;
    margin: 0px 25px;
    width: 10vh; 
}

.tableRemoveBtn {
  background: #F44848 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  border: 1px solid #F44848 ;
  outline: none;
}

.tableProcessBtn {
  background: #4fa06a 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  border: 1px solid #4fa06a ;
  outline: none;
}

.addworkflowProcessBtn {
  background: #ec400c 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
  border: 1px solid #ec400c ;
  outline: none;
}

.btnWithClose {
  background: #D8DADD 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Open Sans';
  font-weight: 600;
  letter-spacing: 0px;
  color: #36405D;
  border: 1px solid #D8DADD ;
  border-radius: 20px;
  outline: none;
}

.pending {
    color: #E09010;
    font-weight: 600;
  }

.completed {
    color: #0b4fce;
    font-weight: 600;
  }
.overdue {
    color: #d14040;
    font-weight: 600;
  }
.inprogress {
    color: green;
    font-weight: 600;
}
.qa_waiting_approval{
  color: rgb(201, 198, 67);
  font-weight: 600;
}
.ready_to_use{
  color: green;
  font-weight: 600;
}
.rejected{
  color: red;
  font-weight: 600;
}
.under_test{
  color: rgb(10, 45, 243);
  font-weight: 600;
}
.quarantine{
  color: #5E1675;
  font-weight: 600;
}

.quantity {
  cursor: pointer;
  text-decoration: underline;
  color: #bc4749 !important;
}

.tableNumberleftAlign {
  text-align: right;
}

  
.custom-switch-class .form-check-input:not(:checked){
  background-color: #c53939;
  border-color: #e4b45b;
  cursor: pointer;
}

.custom-switch-class .form-check-input:checked {
  background-color: #23be76;
  border-color: #2cfd0d;
  cursor: pointer;
}

.disabledArea {
  pointer-events: none;
  background-color: #dcdcdc;
  cursor: no-drop;
}

.disabledAreaEdit::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Slightly transparent overlay */
  pointer-events: all; /* Keeps the element clickable for tooltip */
  z-index: 1;
}

.disabledAreaEdit {
  position: relative;
  background-color: #dcdcdc;
  cursor: no-drop;
}

.borderBottom {
 border-top: 1px solid white;
}

.counter-timer{
  color : #d14040;
  font-size: 1rem !important;
}



/* Wrapper for the entire stats section */
.statsWrapper {
  width: 100%;
  background-color: #f5eded;
  padding: 12px;
  border-radius: 5px;
}

/* Subscription title and toggle container */
.statsHeaderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px; /* Reduce space between title and toggle */
  margin-bottom: 10px;
}

/* Subscription title styling */
.statsHeader {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #333;
  margin: 0; /* Remove any default margin */
}

/* Toggle bar for Active/Inactive status */
.statusToggle {
  width: 50px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.liscenceactive {
  background-color: #28a745; /* Green for Active */
  /* justify-content: flex-end; */
}

.inactive {
  background-color: #dc3545; /* Red for Inactive */
  justify-content: flex-start;
}

.toggleCircle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

/* Container for all stat boxes */
.statsContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

/* Individual stat box */
.statBox {
  background-color: white;
  flex: 1;
  padding: 8px 12px;
  border-radius: 5px;
  text-align: left;
  min-width: 100px;
}

/* Title inside the stat box */
.statTitle {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 12px;
}

/* Values row inside the stat box */
.statValuesRow {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
}
